import styled from 'styled-components';

import { ImageProperties, StyledImageProperties } from './Image.types';

export const StyledImage = styled.img<
    StyledImageProperties & Partial<ImageProperties>
>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    max-width: 100%;
    height: 100%;
    object-fit: cover;

    ${({ alignCenter }) => {
        if (alignCenter) {
            return `
                display: block;
                margin: 0 auto;
            `;
        }

        return ``;
    }}
`;
